<template>
    <div v-if="collectedItems" class="h-100 w-100">
        <login-timer
            :unit="$route.name"
            :user-uuid="$store.getters.getUserAtUnit($route.name).uuid"
        />
        <transition name="fade" mode="out-in">
            <div v-show="!modeSelection" class="h-100 w-100">
                <h1 class="station-title">
                    {{ $t('desk_station_title') }}
                </h1>
                <desk-choose-book
                    :items="deskItems"
                    @itemClick="droppedItemAndZone"
                />
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div v-if="modeSelection" class="h-100 w-100">
                <h1 class="station-title">{{ $t(gameTitle) }}</h1>
                <div
                    class="fowi-btn fowi-btn-primary fowi-btn-sq48 fowi-btn-close"
                    @click="close"
                >
                    <font-awesome-icon :icon="['far', 'times']" size="2x" />
                </div>
                <desk-annotation v-if="modeSelection === 'annotation'" />
                <desk-analysis v-if="modeSelection === 'analysis'" />
            </div>
        </transition>
        <flexaio-login />
    </div>
</template>

<script>
import api from '../../../mixins/api'
import DeskAnnotation from './DeskAnnotation'
import DeskAnalysis from '@/components/stations/3.004_desk/analysis/DeskAnalysis'
import FowiDragAndDropZone from '@/components/global/FowiDragAndDropZone'
import DeskChooseBook from '@/components/stations/3.004_desk/DeskChooseBook'
import FlexaioLogin from '@/components/global/FlexaioLogin'
import LoginTimer from '@/components/global/LoginTimer'

export default {
    name: 'DeskModeSelection',
    components: {
        LoginTimer,
        FlexaioLogin,
        DeskChooseBook,
        DeskAnalysis,
        DeskAnnotation,
        FowiDragAndDropZone,
    },
    data() {
        return {
            heartbeatIntervalId: null,
        }
    },
    computed: {
        gameTitle() {
            if (this.modeSelection) {
                return 'desk_' + this.modeSelection.toLowerCase() + '_title'
            }
            return 'none'
        },
        collectedItems() {
            const items = this.$store.getters
                .getUserAtUnit(this.$route.name)
                .collection.filter(x => x.nfcItem)
            return items
        },
        collectedItemsPI() {
            const items = this.deskItems
            return items ? items.flatMap(x => [x.id]) : null
        },
        analysisItemsPI() {
            const items = this.deskItems.filter(
                x => x.content?.content?.analysis_url?.global,
            )
            return items ? items.flatMap(x => [x.id]) : null
        },
        annotationsItemsPI() {
            const items = this.deskItems.filter(
                x => x.content?.content?.manifest_url?.global,
            )
            return items ? items.flatMap(x => [x.id]) : null
        },
        deskItems() {
            // DESK OBJECTS
            const deskItems = this.collectedItems.filter(x => {
                if (x.nfcItem.units) {
                    const units = JSON.parse(x.nfcItem.units)
                    return units.includes('desk') ? true : false
                }
            })
            return deskItems
        },
        modeSelection() {
            return this.$store.getters['desk/getGame']
        },
    },
    mounted() {
        this.$store.dispatch('desk/resetState')
        /*
        this.heartbeatIntervalId = setInterval(() => {
            const user = this.$store.getters.getUserAtUnit(this.$route.name)
            api.twoavy.stayAtUnit(user.uuid, user.unit)
        }, 20 * 1000)
        */
    },
    beforeDestroy() {
        // clearInterval(this.heartbeatIntervalId)
    },
    methods: {
        close() {
            this.$store.dispatch('desk/resetState')
        },
        droppedItemAndZone(event) {
            let game = null
            switch (event.zone) {
                case 'drop-zone-1':
                    game = 'annotation'
                    break
                case 'drop-zone-2':
                    game = 'analysis'
                    break
                case 'drop-zone-3':
                    game = 'annotation'
                    break
            }
            if (game) {
                const item = event.item
                this.$store.dispatch('desk/setGameAndItem', { game, item })
            }
        },
    },
}
</script>
