var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"colored-overlay",staticClass:"annotation-overlay position-fixed vw-100 vh-100"},[_c('div',{staticClass:"place-annotation-overlay",class:_vm.$store.state.desk.openInfoModalOnSide === 'left'
                ? 'move-left'
                : 'move-right'},[_c('div',{style:({
                position: 'relative',
                height: _vm.$store.state.desk.svgHeight + 'px',
            })},[_c('BoxWrapper',{ref:"actualOverlay",attrs:{"set-close-to-bottom":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{staticClass:"button clickable close-info m-2 border-0",on:{"click":_vm.cancelAnnotation}},[_c('font-awesome-icon',{attrs:{"size":"lg","icon":['far', 'times']}})],1)]},proxy:true},{key:"content",fn:function(){return [(_vm.newAnnotation || _vm.userIsLoggedIn)?_c('div',{staticClass:"create-annotation-content"},[(_vm.newAnnotation)?[_c('div',{staticClass:"type-selection"},[_c('p',[_vm._v("Type:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.annotationTypes),function(option){return _c('option',{key:'type_' + option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0)]),(_vm.type === 'text')?_c('editor',{attrs:{"placeholder":"Tragen Sie hier Ihren text ein.","api-key":_vm.apiKey,"init":_vm.init},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.url),expression:"url"}],style:(!_vm.isValidURL && {
                                        border: '2px solid red',
                                    }),attrs:{"placeholder":"URL"},domProps:{"value":(_vm.url)},on:{"input":function($event){if($event.target.composing)return;_vm.url=$event.target.value}}}),_c('div',{staticClass:"align-self-end"},[_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48",on:{"click":_vm.saveAnnotation}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'cloud-upload'],"size":"2x"}})],1)])]:[_c('div',{staticClass:"type-selection"},[_c('p',[_vm._v("Type:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.annotationTypes),function(option){return _c('option',{key:'type_' + option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0)]),(_vm.type === 'text')?_c('editor',{attrs:{"placeholder":"Tragen Sie hier Ihren text ein.","api-key":_vm.apiKey,"init":_vm.init},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.url),expression:"url"}],style:(!_vm.isValidURL && {
                                        border: '2px solid red',
                                    }),domProps:{"value":(_vm.url)},on:{"input":function($event){if($event.target.composing)return;_vm.url=$event.target.value}}}),_c('div',{staticClass:"align-self-end d-flex"},[_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48 bg-danger",on:{"click":_vm.deleteAnnotation}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash'],"size":"lg"}})],1),_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48",on:{"click":_vm.updateAnnotation}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'cloud'],"size":"lg"}})],1)])]],2):[(_vm.annotationModel.text !== '')?_c('p',{staticClass:"p-2",domProps:{"innerHTML":_vm._s(_vm.annotationModel.text)}}):_vm._e(),(_vm.annotationModel.url)?_c('div',{staticClass:"iframe-wrapper"},[_c('iframe',{attrs:{"height":"600","width":"532","src":_vm.annotationModel.url}})]):_vm._e()]]},proxy:true}])})],1)]),(_vm.newAnnotation || _vm.userIsLoggedIn)?_c('div',{staticClass:"keyboard d-flex w-100 justify-content-center"},[_c('fo-wi-keyboard',{on:{"onKeyPress":_vm.handleKeyboard}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }