<template>
    <transition-group
        v-if="isMounted"
        tag="div"
        class="book-shelf d-flex flex-wrap"
        name="fade"
        appear
        mode="in-out"
    >
        <desk-book-cover
            v-if="wordCloudManifest"
            :key="'book_cover' + wordCloudManifestUrl"
            class="d-flex flex-column book-card"
            :item="getWorldCloudItem"
            :style="`animation-delay: 0s; animation-duration: .5s;`"
            @openBook="openBook"
        />
        <desk-book-cover
            v-for="(item, index) in items.filter(x => x.thumbnailUrl)"
            :key="'book_cover' + item.id"
            class="d-flex flex-column book-card"
            :item="item"
            :style="
                `animation-delay: ${(index + 1) *
                    0.5}s; animation-duration: .5s;`
            "
            @openBook="openBook"
        />
    </transition-group>
</template>

<script>
import DeskBookCover from '@/components/stations/3.004_desk/DeskBookCover'

export default {
    name: 'DeskChooseBook',
    components: { DeskBookCover },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isMounted: false,
            wordCloudManifestUrl:
                'https://manifests.sub.uni-goettingen.de/iiif/presentation/DE-611-HS-3461926/manifest',
            wordCloudManifest: null,
        }
    },
    computed: {
        getWorldCloudItem() {
            const thumbnail =
                this.wordCloudManifest.thumbnail['@id'].substring(
                    0,
                    this.wordCloudManifest.thumbnail['@id'].indexOf('full/'),
                ) + 'full/,300/0/default.jpg'
            console.log(thumbnail)
            return {
                analysis: this.wordCloudManifest,
                nfcItem: {
                    analysis_url: this.wordCloudManifestUrl,
                },
                thumbnailUrl: thumbnail,
            }
        },
    },
    async mounted() {
        await fetch(this.wordCloudManifestUrl)
            .then(response => response.json())
            .then(data => {
                this.wordCloudManifest = data
            })
        this.items.forEach(item => {
            this.thumbnailUrl(item)
        })
        this.isMounted = true
    },
    methods: {
        async thumbnailUrl(item) {
            if (item.thumbnailUrl) {
                item.thumbnailUrl =
                    item.thumbnailUrl.substring(
                        0,
                        item.thumbnailUrl.indexOf('full/'),
                    ) + 'full/,300/0/default.jpg'
            }
        },
        openBook(event) {
            console.log(event)
            this.$emit('itemClick', event)
        },
        getLabel(item) {
            if (item.content?.content?.label) {
                return item.content?.content?.label[
                    this.$store.state.users.desk.settings.language
                ]
                    ? item.content?.content?.label[
                          this.$store.state.users.desk.settings.language
                      ]
                    : item.content?.content?.label[0]
            }
            return item.manifest.label.substring(0, 40)
        },
    },
}
</script>

<style lang="scss" scoped>
.book-shelf {
    gap: calc(25vw * var(--pxToVw));
    row-gap: calc(25vw * var(--pxToVw));
    margin: calc(125vw * var(--pxToVw)) calc(50vw * var(--pxToVw))
        calc(25vw * var(--pxToVw)) calc(50vw * var(--pxToVw));
}
</style>
