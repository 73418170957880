var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.manifest)?_c('div',{staticClass:"d-flex justify-content-end flex-column align-items-center",class:!_vm.analysis ? 'full-height vw-100' : '',attrs:{"id":"iiif-viewer"}},[_c('div',{ref:"iiif",staticClass:"d-flex viewer-container",style:(_vm.containerSize)},[_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.autoactive && !_vm.analysis && _vm.generatePostItsTop)?_c('iiif-post-it',{key:'iiif-viewer-post-its-top-' + _vm.index,attrs:{"even-page-id":_vm.evenPageId,"odd-page-id":_vm.oddPageId,"post-its":_vm.generatePostItsTop,"position":"top"},on:{"click":_vm.jumpToPage}}):_vm._e(),(!_vm.autoactive && !_vm.analysis && _vm.generatePostItsBottom)?_c('iiif-post-it',{key:'iiif-viewer-post-its-bottom-' + _vm.index,attrs:{"even-page-id":_vm.evenPageId,"odd-page-id":_vm.oddPageId,"post-its":_vm.generatePostItsBottom,"position":"bottom"},on:{"click":_vm.jumpToPage}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:'iiif-viewer-' + _vm.index,staticClass:"d-flex"},[(_vm.manifest.sequences[0].canvases[_vm.index])?_c('iiif-viewer-page',{attrs:{"customized-url":_vm.customUrl(),"height":_vm.height,"show-transcription":_vm.showTranscription,"transcription":_vm.transcription.left,"manifest-id":_vm.manifest['@id'],"manifest-page":_vm.manifest.sequences[0].canvases[_vm.index],"edit-mode":_vm.$store.getters['desk/getIsLoggedIn'],"annotations":_vm.getPageAnnotation(
                            _vm.manifest.sequences[0].canvases[_vm.index].images[0]
                                .resource.service['@id'],
                        )}}):_vm._e(),(
                        _vm.manifest.sequences[0].canvases[_vm.index + 1] &&
                            _vm.index !== 0
                    )?_c('iiif-viewer-page',{attrs:{"customized-url":_vm.customUrl(),"height":_vm.height,"show-transcription":_vm.showTranscription,"transcription":_vm.transcription.right,"manifest-id":_vm.manifest['@id'],"manifest-page":_vm.manifest.sequences[0].canvases[_vm.index + 1],"edit-mode":_vm.$store.getters['desk/getIsLoggedIn'],"annotations":_vm.getPageAnnotation(
                            _vm.manifest.sequences[0].canvases[_vm.index + 1]
                                .images[0].resource.service['@id'],
                        )}}):_vm._e()],1)]),_c('div',{staticClass:"preload"},[(_vm.manifest.sequences[0].canvases[_vm.index + 1])?_c('img',{attrs:{"src":_vm.manifest.sequences[0].canvases[_vm.index + 1].images[0]
                        .resource.service['@id'] +
                        '/full/,' +
                        _vm.height +
                        '/0/default.jpg'}}):_vm._e(),(_vm.manifest.sequences[0].canvases[_vm.index + 2])?_c('img',{attrs:{"src":_vm.manifest.sequences[0].canvases[_vm.index + 2].images[0]
                        .resource.service['@id'] +
                        '/full/,' +
                        _vm.height +
                        '/0/default.jpg'}}):_vm._e()])],1),(!_vm.autoactive)?[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"fowi-btn fowi-btn-natural fowi-btn-sq48 mr-4",class:_vm.index === 0 ? 'disabled' : '',on:{"click":_vm.prevPage}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-left'],"size":"2x"}})],1),_c('h3',{staticClass:"p-0 m-0 mr-4"},[_vm._v(" "+_vm._s(_vm.$t('desk_page'))+" "+_vm._s(_vm.index + 1)+" / "+_vm._s(_vm.lastIndex + 1)+" ")]),_c('div',{staticClass:"fowi-btn fowi-btn-natural fowi-btn-sq48",class:_vm.index === _vm.lastIndex - 1 ? 'disabled' : '',on:{"click":_vm.nextPage}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'angle-right'],"size":"2x"}})],1)]),(!_vm.autoactive)?_c('div',{staticClass:"iiif-viewer-sub-menu d-flex",class:_vm.analysis ? 'analysis' : ''},[(_vm.analysis)?_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48 mr-2",class:_vm.transcription.left === '' && _vm.transcription.right === ''
                        ? 'disabled'
                        : '',on:{"click":_vm.toggleShowTranscription}},[_c('font-awesome-icon',{attrs:{"icon":[
                        'far',
                        _vm.showTranscription ? 'remove-format' : 'text',
                    ],"size":"lg"}})],1):_vm._e(),_c('div',{staticClass:"fowi-btn fowi-btn-primary btn-fowi-structures fowi-btn-sq48 mr-2",class:[
                    typeof _vm.manifest.structures !== 'object'
                        ? 'disabled'
                        : '',
                    _vm.structureModalOpen ? 'active text-white' : '',
                ],on:{"click":_vm.openStructureModal}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'list-ul'],"size":"lg"}})],1),_c('div',{staticClass:"fowi-btn fowi-btn-primary btn-fowi-metadata fowi-btn-sq48 mr-2",class:_vm.metadataModalOpen ? 'active text-white' : '',on:{"click":_vm.openMetadataModal}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'info-circle'],"size":"2x"}})],1)]):_vm._e(),(_vm.manifest.structures)?_c('iiif-structures',{attrs:{"current-pages":_vm.getPageIds,"open-modal":_vm.structureModalOpen,"structures":_vm.manifest.structures},on:{"close":_vm.closeStructureModal,"setNewPage":_vm.jumpToPageByCanvasId}}):_vm._e(),(_vm.manifest.metadata)?_c('iiif-metadata',{attrs:{"label":_vm.manifest.label,"open-modal":_vm.metadataModalOpen,"metadata":_vm.manifest.metadata},on:{"close":_vm.closeMetadataModal}}):_vm._e()]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }