<template>
    <div
        ref="colored-overlay"
        class="annotation-overlay position-fixed vw-100 vh-100"
    >
        <div
            class="place-annotation-overlay"
            :class="
                $store.state.desk.openInfoModalOnSide === 'left'
                    ? 'move-left'
                    : 'move-right'
            "
        >
            <div
                :style="{
                    position: 'relative',
                    height: $store.state.desk.svgHeight + 'px',
                }"
            >
                <BoxWrapper ref="actualOverlay" set-close-to-bottom>
                    <template #button>
                        <button
                            class="button clickable close-info m-2 border-0"
                            @click="cancelAnnotation"
                        >
                            <font-awesome-icon
                                size="lg"
                                :icon="['far', 'times']"
                            />
                        </button>
                    </template>
                    <template #content>
                        <div
                            v-if="newAnnotation || userIsLoggedIn"
                            class="create-annotation-content"
                        >
                            <template v-if="newAnnotation">
                                <div class="type-selection">
                                    <p>Type:</p>
                                    <select v-model="type">
                                        <option
                                            v-for="option in annotationTypes"
                                            :key="'type_' + option"
                                            :value="option"
                                        >
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <editor
                                    v-if="type === 'text'"
                                    v-model="text"
                                    placeholder="Tragen Sie hier Ihren text ein."
                                    :api-key="apiKey"
                                    :init="init"
                                />
                                <input
                                    v-else
                                    v-model="url"
                                    :style="
                                        !isValidURL && {
                                            border: '2px solid red',
                                        }
                                    "
                                    placeholder="URL"
                                />
                                <div class="align-self-end">
                                    <div
                                        class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                                        @click="saveAnnotation"
                                    >
                                        <font-awesome-icon
                                            :icon="['far', 'cloud-upload']"
                                            size="2x"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="type-selection">
                                    <p>Type:</p>
                                    <select v-model="type">
                                        <option
                                            v-for="option in annotationTypes"
                                            :key="'type_' + option"
                                            :value="option"
                                        >
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <editor
                                    v-if="type === 'text'"
                                    v-model="text"
                                    placeholder="Tragen Sie hier Ihren text ein."
                                    :api-key="apiKey"
                                    :init="init"
                                />
                                <input
                                    v-else
                                    v-model="url"
                                    :style="
                                        !isValidURL && {
                                            border: '2px solid red',
                                        }
                                    "
                                />
                                <div class="align-self-end d-flex ">
                                    <div
                                        class="fowi-btn fowi-btn-primary fowi-btn-sq48 bg-danger"
                                        @click="deleteAnnotation"
                                    >
                                        <font-awesome-icon
                                            :icon="['far', 'trash']"
                                            size="lg"
                                        />
                                    </div>
                                    <div
                                        class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                                        @click="updateAnnotation"
                                    >
                                        <font-awesome-icon
                                            :icon="['far', 'cloud']"
                                            size="lg"
                                        />
                                    </div>
                                </div>
                            </template>
                        </div>
                        <template v-else>
                            <p
                                v-if="annotationModel.text !== ''"
                                class="p-2"
                                v-html="annotationModel.text"
                            />
                            <div
                                v-if="annotationModel.url"
                                class="iframe-wrapper"
                            >
                                <iframe
                                    height="600"
                                    width="532"
                                    :src="annotationModel.url"
                                />
                            </div>
                        </template>
                    </template>
                </BoxWrapper>
            </div>
        </div>
        <div
            v-if="newAnnotation || userIsLoggedIn"
            class="keyboard d-flex w-100 justify-content-center "
        >
            <fo-wi-keyboard @onKeyPress="handleKeyboard" />
        </div>
    </div>
</template>

<script>
import api from '../../../mixins/api'
import FoWiKeyboard from '@/components/global/FoWiKeyboard'
import BoxWrapper from '@/components/global/BoxWrapper'
import Editor from '@tinymce/tinymce-vue'

export default {
    name: 'DeskNewAnnotationOverlay',
    components: { BoxWrapper, FoWiKeyboard, Editor },
    props: {
        annotationModel: {
            type: Object,
            required: true,
        },
        newAnnotation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            text: '',
            url: '',
            type: 'text',
            annotationTypes: ['text', 'url'],
            urlRegex:
                '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})',
            apiKey: 'c9kxwmlosfk0pm4jnj8j1pm8hzprlnt04hhftgpsnunje615',
            init: {
                height: 400,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code',
                ],
                toolbar: 'undo redo | bold italic | removeformat',
            },
        }
    },
    computed: {
        userIsLoggedIn() {
            return this.$store.getters['desk/getIsLoggedIn']
        },
        isValidURL() {
            return !!this.text.match(this.urlRegex)
        },
    },
    mounted() {
        if (this.annotationModel && !this.newAnnotation) {
            this.type = this.annotationModel.text ? 'text' : 'url'
            this.text = this.annotationModel.text
            this.url = this.annotationModel.url
        }
        // console.log(this.annotationModel)
    },
    methods: {
        handleKeyboard(key) {
            if (key !== '{bksp}') {
                this.text = this.text + key
            }
            if (key === '{bksp}') {
                this.text = this.text.slice(0, -1)
            }
        },
        async saveAnnotation() {
            let payload = { ...this.annotationModel }
            payload.type = this.type
            if (this.type === 'url') {
                payload.text = ''
                payload.url = this.url
            } else {
                payload.text = this.text
                payload.url = ''
            }

            await api.twoavy.postDeskNewAnnotation(payload)
            this.$emit('add-annotation')
            this.cancelAnnotation()
        },
        async updateAnnotation() {
            let payload = { ...this.annotationModel }
            payload.type = this.type
            if (this.type === 'url') {
                payload.text = ''
                payload.url = this.url
            } else {
                payload.text = this.text
                payload.url = ''
            }

            await api.twoavy.putDeskNewAnnotation(
                this.annotationModel.id,
                payload,
            )
            this.$emit('update-annotation')
            this.cancelAnnotation()
        },
        async deleteAnnotation() {
            if (confirm('Wirklich Löschen?') == true) {
                await api.twoavy.deleteDeskNewAnnotation(
                    this.annotationModel.id,
                )
                setTimeout(() => {
                    this.$emit('delete-annotation')
                    this.cancelAnnotation()
                }, 250)
            }
        },
        cancelAnnotation() {
            setTimeout(() => {
                this.$root.$emit('close_annotation')
            }, 300)
            this.$emit('cancel-annotation')
        },
    },
}
</script>

<style lang="scss" scoped>
.close-info {
    z-index: 1000;
    pointer-events: auto;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
    }
}

.create-annotation-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 2vh;
    padding: 2vh;

    .type-selection {
        height: auto;
        display: flex;
        flex-direction: row;
        grid-gap: 1vh;

        p {
            margin: 0;
        }
    }

    textarea {
        height: 20vh;
    }

    input {
        outline: none;
    }

    select {
        width: 20%;
    }
}

.annotation-overlay {
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;

    .place-annotation-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .relativeIt {
            position: relative;
        }
    }

    .move-right {
        left: calc(50vw * var(--pxToVw));
        justify-content: flex-start;
    }

    .move-left {
        right: calc(50vw * var(--pxToVw));
        justify-content: flex-end;
    }

    .annotationDisplayWrapper {
        width: 20vw;
        height: 30vh;
        background-color: white;
    }

    .innerFields {
        display: flex;
        flex-direction: row;
        grid-gap: 1vw;
        flex-grow: 1;

        img {
            width: 35%;
            height: auto;
            border: 2px solid white;
        }

        p {
            display: inline-block;
            flex-grow: 1;
            border: 2px solid white;
        }
    }

    .keyboard {
        position: absolute;
        bottom: 0;

        .simple-keyboard {
            max-width: 1050px;
        }
    }

    .iframe-wrapper {
        padding: calc(8vw * var(--pxToVw));
        padding-bottom: calc(0vw * var(--pxToVw));
        display: inline-block;
        -webkit-overflow-scrolling: touch !important;
        overflow-y: auto !important;
    }
}
</style>
