var render = function render(){var _vm=this,_c=_vm._self._c;return _c('box-wrapper',{staticClass:"position-fixed d-flex flex-column",class:_vm.openModal ? '' : 'hide',attrs:{"id":"iiif-structures","title":_vm.$t('structures')},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48",on:{"click":function($event){return _vm.$emit('close')}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'times'],"size":"2x"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column p-3"},_vm._l((_vm.structures),function(structurePart,index){return _c('div',{key:'structures-' + index},[_c('h5',{staticClass:"m-0 mb-2 p-0",class:structurePart.within ? 'ml-4' : '',on:{"click":function($event){return _vm.setNewPage(structurePart)}}},[(
                            structurePart.canvases.includes(
                                _vm.currentPages[0],
                            ) ||
                                structurePart.canvases.includes(
                                    _vm.currentPages[1],
                                )
                        )?_c('span',{staticClass:"marker"}):_c('font-awesome-icon',{attrs:{"icon":['far', 'bookmark'],"size":"sm"}}),_vm._v(" "+_vm._s(structurePart.label)+" ")],1)])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }