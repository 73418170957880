<template>
    <div
        :class="'btn-pos-' + position"
        class="btn-pos position-absolute d-flex flex-column"
    >
        <div
            v-for="postIt in postIts"
            :key="'post_it_key_' + postIt.page"
            class="fowi-btn-fit-content fowi-btn fowi-btn-post-it my-1"
            :class="
                postIt.label === evenPageId || postIt.label === oddPageId
                    ? 'opacity-0'
                    : 'opacity-100'
            "
            @click="$emit('click', postIt.label)"
        >
            {{ $t('desk_page') }} {{ postIt.label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'IiifPostIt',
    props: {
        evenPageId: {
            type: Number,
            required: true,
        },
        oddPageId: {
            type: Number,
            required: true,
        },
        position: {
            type: String,
            default: 'left',
            required: false,
            validate: value => {
                return ['left', 'right', 'top', 'bottom'].indexOf(value) !== -1
            },
        },
        postIts: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-pos {
    .fowi-btn {
        transition: all 0.25s ease-in-out;
    }

    &-top {
        align-items: flex-end;
        transform-origin: top right;
        transform: translateX(-100%) rotate(90deg) translateY(-100%);

        div {
            margin-right: -2px;
        }
    }

    &-bottom {
        bottom: 0;
        transform-origin: bottom left;
        transform: rotate(90deg);
    }

    &-left {
        transform: translateX(-100%);
    }

    &-right {
        right: 0;
        transform: translateX(100%);
    }
}
</style>
