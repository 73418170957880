<template>
    <transition name="fade" mode="out-in">
        <desk-mode-selection v-if="$store.getters.getUserAtUnit($route.name)" />
        <desk-idle v-else-if="$store.getters.getContent" />
    </transition>
</template>

<script>
import DeskIdle from './DeskIdle'
import DeskModeSelection from './DeskModeSelection'

export default {
    name: 'DeskMain',
    components: { DeskModeSelection, DeskIdle },
    mounted() {
        this.$store.dispatch('desk/resetState')
    },
    methods: {},
}
</script>

<style scoped lang="scss"></style>
