var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iiif-viewer-page position-relative"},[(_vm.prefixImageUrl && _vm.appendImageUrl)?_c('img',{attrs:{"src":_vm.prefixImageUrl + _vm.height + _vm.appendImageUrl},on:{"load":_vm.getSvgSize}}):_vm._e(),(_vm.svgContainer)?_c('div',{staticClass:"annotation-box",class:_vm.getPageId % 2 ? 'even' : 'uneven'},[_c('svg',{attrs:{"width":_vm.svgContainer.width,"height":_vm.svgContainer.height,"viewBox":'0 0 ' + _vm.svgContainer.width + ' ' + _vm.svgContainer.height,"xmlns":"http://www.w3.org/2000/svg"},on:{"touchstart":_vm.startDrawRect,"touchmove":_vm.moveDrawRect,"touchend":_vm.endDrawRect}},[_vm._l((_vm.annotations),function(annotation,index){return [_c('rect',{key:'svg-annotation-rect-' + index,attrs:{"x":_vm.$store.state.desk.svgWidth *
                            annotation.params.position.x,"y":_vm.$store.state.desk.svgHeight *
                            annotation.params.position.y,"width":_vm.$store.state.desk.svgWidth *
                            annotation.params.size.width,"height":_vm.$store.state.desk.svgHeight *
                            annotation.params.size.height,"rx":_vm.roundCorner,"ry":_vm.roundCorner,"fill":"transparent","stroke":"white","stroke-width":"2"},on:{"touchstart":function($event){$event.stopPropagation();return _vm.openAnnotation(annotation)},"click":function($event){$event.stopPropagation();return _vm.openAnnotation(annotation)}}}),_c('line',{key:'svg-annotation-line-' + index,attrs:{"x1":_vm.getPageId % 2
                            ? annotation.params.position.x *
                                  _vm.$store.state.desk.svgWidth +
                              annotation.params.size.width *
                                  _vm.$store.state.desk.svgWidth
                            : annotation.params.position.x *
                              _vm.$store.state.desk.svgWidth,"y1":annotation.params.position.y *
                            _vm.$store.state.desk.svgHeight +
                            (annotation.params.size.height *
                                _vm.$store.state.desk.svgHeight) /
                                2,"x2":_vm.getPageId % 2
                            ? _vm.svgContainer.width + _vm.circleDiameter / 2
                            : -_vm.circleDiameter / 2,"y2":annotation.params.position.y *
                            _vm.$store.state.desk.svgHeight +
                            (annotation.params.size.height *
                                _vm.$store.state.desk.svgHeight) /
                                2,"stroke":"white"}}),_c('rect',{key:'svg-annotation-click-rect-' + index,staticClass:"clickable",attrs:{"x":_vm.getPageId % 2
                            ? _vm.svgContainer.width + _vm.circleDiameter / 2
                            : -(_vm.circleDiameter + _vm.circleDiameter / 2),"y":_vm.$store.state.desk.svgHeight *
                            annotation.params.position.y +
                            (_vm.$store.state.desk.svgHeight *
                                annotation.params.size.height) /
                                2 -
                            _vm.circleDiameter / 2,"fill":"white","width":_vm.circleDiameter,"height":_vm.circleDiameter},on:{"touchstart":function($event){$event.stopPropagation();return _vm.openAnnotation(annotation)},"click":function($event){$event.stopPropagation();return _vm.openAnnotation(annotation)}}}),_c('svg',{key:'svg-annotation-icon-' + index,staticClass:"pointer-events-none",attrs:{"viewBox":"0 0 100 100","x":_vm.getPageId % 2
                            ? _vm.svgContainer.width +
                              (_vm.circleDiameter -
                                  (_vm.circleDiameter * _vm.faIconScaleFactor) / 2)
                            : -(
                                  _vm.circleDiameter +
                                  (_vm.circleDiameter * _vm.faIconScaleFactor) / 2
                              ),"y":_vm.$store.state.desk.svgHeight *
                            annotation.params.position.y +
                            (_vm.$store.state.desk.svgHeight *
                                annotation.params.size.height) /
                                2 -
                            (_vm.circleDiameter / 2) * _vm.faIconScaleFactor,"width":_vm.circleDiameter * _vm.faIconScaleFactor,"height":_vm.circleDiameter * _vm.faIconScaleFactor}},[(annotation.type === 'url')?_c('font-awesome-icon',{staticClass:"fa-icon",attrs:{"icon":['far', 'external-link']}}):(annotation.type === 'object_link')?_c('font-awesome-icon',{staticClass:"fa-icon",attrs:{"icon":['far', 'object-group']}}):(annotation.type === 'text')?_c('font-awesome-icon',{staticClass:"fa-icon",attrs:{"icon":['far', 'comment']}}):_c('font-awesome-icon',{staticClass:"fa-icon",attrs:{"icon":['far', 'comment-lines']}})],1)]}),(_vm.annotationSvg.rect)?[_c('rect',{attrs:{"x":_vm.annotationSvg.rect.x,"y":_vm.annotationSvg.rect.y,"width":_vm.annotationSvg.rect.width,"height":_vm.annotationSvg.rect.height,"rx":_vm.roundCorner,"ry":_vm.roundCorner,"fill":"transparent","stroke":"red"}})]:_vm._e()],2)]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.showTranscription && _vm.transcription)?_c('div',{staticClass:"transcription-box",class:_vm.getPageId % 2 ? 'even' : 'uneven'},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.transcription)}})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }