<template>
    <div
        class="d-flex flex-column book-card"
        @click="
            item.nfcItem.manifest_url ? openBook(item, 'drop-zone-1') : null
        "
    >
        <img
            v-if="item.thumbnailUrl"
            class="card-img-top"
            :src="item.thumbnailUrl"
        />
        <div
            class="card-img-overlay p-0 d-flex flex-column justify-content-between"
        >
            <h3 class="book-cover-label">
                {{ getLabel(item) }}
            </h3>
            <div class="d-flex m-3 justify-content-end">
                <div
                    v-if="item.nfcItem.manifest_url"
                    class="fowi-btn fowi-btn-h48 fowi-btn-secondary"
                    @click.stop="openBook(item, 'drop-zone-1')"
                >
                    <h5 class="m-0">
                        {{ $t('desk_annotation_title') }}
                    </h5>
                </div>
                <div
                    v-if="item.nfcItem.analysis_url"
                    class="fowi-btn fowi-btn-h48 fowi-btn-secondary"
                    @click.stop="openBook(item, 'drop-zone-2')"
                >
                    <h5 class="m-0">
                        {{ $t('desk_analysis_title') }}
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeskBookCover',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async thumbnailUrl(item) {
            if (item.thumbnailUrl) {
                item.thumbnailUrl =
                    item.thumbnailUrl.substring(
                        0,
                        item.thumbnailUrl.indexOf('full/'),
                    ) + 'full/,300/0/default.jpg'
            }
        },
        openBook(item, zone) {
            this.$emit('openBook', {
                zone: zone,
                item: item,
            })
        },
        getLabel(item) {
            if (item.content?.content?.label) {
                return item.content?.content?.label[
                    this.$store.state.users.desk.settings.language
                ]
                    ? item.content?.content?.label[
                          this.$store.state.users.desk.settings.language
                      ]
                    : item.content?.content?.label[0]
            }
            if (item.analysis?.label) {
                return item.analysis.label.substring(0, 40)
            }
            return item.manifest.label.substring(0, 40)
        },
    },
}
</script>

<style lang="scss" scoped>
$height: 280vw;

.book-card {
    width: calc(#{$height} * var(--pxToVw));
    height: calc(#{$height} * var(--pxToVw));

    background-clip: content-box;
    background-color: black;
    position: relative;

    .book-cover-label,
    h2,
    h3 {
        font-weight: 700;
        padding: calc(8vw * var(--pxToVw)) calc(16vw * var(--pxToVw));
        background-color: rgba(0, 0, 0, 0.5);
    }

    img {
        height: calc(#{$height} * var(--pxToVw));
        object-fit: contain;
    }
}

.book-shelf {
    gap: calc(25vw * var(--pxToVw));
    row-gap: calc(25vw * var(--pxToVw));
    margin: calc(125vw * var(--pxToVw)) calc(50vw * var(--pxToVw))
        calc(25vw * var(--pxToVw)) calc(50vw * var(--pxToVw));
}
</style>
