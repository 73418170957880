<template>
    <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
    name: 'FoWiKeyboard',
    props: {
        keyboardClass: {
            default: 'simple-keyboard',
            type: String,
        },
    },
    data() {
        return {
            keyboard: null,
            shiftPressed: false,
            capslockPressed: false,
        }
    },
    watch: {
        input(input) {
            this.keyboard.setInput(input)
        },
    },
    mounted() {
        this.keyboard = new Keyboard(this.keyboardClass, {
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            display: {
                '{bksp}': '⌫',
                '{tab}': '⇥',
                '{lock}': '⇪',
                '{shift}': '⇧',
                '{enter}': '↩',
                '{space}': ' ',
            },
        })
    },
    methods: {
        onChange(input) {
            this.$emit('onChange', input)
        },
        onKeyPress(button) {
            switch (button) {
                case '{shift}':
                    if (!this.capslockPressed) {
                        this.shiftPressed = !this.shiftPressed
                        this.handleShift()
                    }
                    return
                case '{lock}':
                    this.capslockPressed = !this.capslockPressed
                    this.handleShift()
                    return
                case '{space}':
                    button = ' '
                    break
                case '{enter}':
                    button = '\n'
                    break
            }

            this.$emit('onKeyPress', button)
            if (this.shiftPressed) {
                this.shiftPressed = false
                this.handleShift()
            }
        },
        handleShift() {
            let currentLayout = this.keyboard.options.layoutName
            let shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

            this.keyboard.setOptions({
                layoutName: shiftToggle,
            })
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.hg-button {
    color: black;
}

//@import 'assets/scss/variables';
/*
.simple-keyboard.hg-theme-default .hg-button {
    height: calc(115vh * var(--pxToVw));
}

.simple-keyboard.hg-theme-default .hg-button {
    background-color: white;
    color: #fff;
    font-size: calc(50vh * var(--pxToVw));
}

.simple-keyboard {
    position: absolute;
    bottom: 7.5vh;
    width: 69vw;
    left: 50%;
    transform: translateX(-50%);

    .hg-row:nth-child(1) {
        padding-right: 2.875vw;
    }
    .hg-row:nth-child(2) {
        padding-left: 2.875vw;
    }
    .hg-row:nth-child(3) {
        padding-left: 5.75vw;
    }

    .hg-button {
        width: 5.75vw;
        span {
            color: var(--primary);
        }
        &-bksp {
            width: 8.625vw;
            margin-left: 12.075vw;
        }
    }
}

.keyboard input {
    background-color: #9e917a;
    color: #fff;
    font-size: calc(32vh * var(--pxToVw));
    border-radius: calc(20vh * var(--pxToVw));
    margin-bottom: calc(50vh * var(--pxToVw));
    width: 90%;
    border: none;
    padding: calc(5vh * var(--pxToVw)) calc(25vh * var(--pxToVw));
}

::placeholder {
    color: #fff;
    font-size: calc(32vh * var(--pxToVw));
}
*/
</style>
