<template>
    <div class="login-componente">
        <div
            class="fowi-btn-login fowi-btn fowi-btn-primary"
            @click="handleLoginLogout"
        >
            {{ $store.getters['desk/getIsLoggedIn'] ? 'Logout' : 'Login' }}
        </div>
        <div v-if="showLogin" class="login-field">
            <div
                class="fowi-btn fowi-btn-primary fowi-btn-sq48 close"
                @click="showLogin = false"
            >
                <font-awesome-icon :icon="['far', 'times']" size="2x" />
            </div>
            <div>
                <div class="inner-divs">
                    <p>E-Mail</p>
                    <input v-model="loginEmail" type="email" />
                </div>
                <div class="inner-divs">
                    <p>Passwort</p>
                    <input v-model="loginPassword" type="password" />
                </div>
                <div
                    class="fowi-btn fowi-btn-primary fowi-btn-h48"
                    @click="submitLogin"
                >
                    <font-awesome-icon :icon="['far', 'sign-in']" size="2x" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/mixins/api'

export default {
    name: 'FlexaioLogin',
    data() {
        return {
            showLogin: false,
            loginEmail: '',
            loginPassword: '',
        }
    },
    methods: {
        async submitLogin() {
            let token
            await api.twoavy
                .loginUser(this.loginEmail, this.loginPassword)
                .then(result => {
                    token = result
                })
            if (token.data?.accessToken) {
                this.showLogin = false
                await this.$store.dispatch(
                    'desk/setUserToken',
                    token.data?.accessToken,
                )
            }
        },
        async handleLoginLogout() {
            if (this.$store.getters['desk/getIsLoggedIn']) {
                await this.$store.dispatch('desk/setUserToken', null)
            } else {
                this.showLogin = true
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.fowi-btn-login {
    position: absolute;
    bottom: 5vh;
    left: 5vh;
}

.login-field {
    position: absolute;
    bottom: 5vh;
    left: 5vh;
    width: 25vw;
    height: 25vh;
    background-color: white;
    padding: 2vh;

    .close {
        position: absolute;
        right: 2vh;
        top: 2vh;
        opacity: 1;
    }

    div {
        display: flex;
        flex-direction: column;
        grid-gap: 4ch;

        .inner-divs {
            display: flex;
            flex-direction: column;
            grid-gap: 0;
        }

        p {
            color: black;
            margin: 0;
        }

        input {
            width: 80%;
            height: 4ch;
        }

        button {
            width: 30% !important;
        }
    }
}
</style>
