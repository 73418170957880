var render = function render(){var _vm=this,_c=_vm._self._c;return _c('box-wrapper',{staticClass:"position-fixed d-flex flex-column",class:_vm.openModal ? '' : 'hide',attrs:{"id":"iiif-metadata","title":_vm.label},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48",on:{"click":function($event){return _vm.$emit('close')}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'times'],"size":"2x"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column p-3"},_vm._l((_vm.metadata),function(metadataPart,index){return _c('div',{key:'metadata-' + index},[_c('h5',{staticClass:"m-0 mb-1 p-0"},[_vm._v(" "+_vm._s(metadataPart.label)+" ")]),_c('p',{staticClass:"m-0 p-0 pb-3"},[(
                            typeof metadataPart.value === 'string' &&
                                metadataPart.label !== 'PURL'
                        )?_c('span',{domProps:{"innerHTML":_vm._s(metadataPart.value)}}):(
                            typeof metadataPart.value === 'string' &&
                                metadataPart.label === 'PURL'
                        )?_c('qrcode-vue',{attrs:{"value":metadataPart.value,"size":150,"level":"M","background":"#818181","foreground":"#ffffff"}}):(typeof metadataPart.value === 'object')?_vm._l((metadataPart.value),function(part){return _c('span',{key:'part-' + part.toString(),domProps:{"innerHTML":_vm._s(part)}},[_vm._v(" "+_vm._s(part)+" ")])}):_vm._e()],2)])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }