<template>
    <div class="h-100 w-100">
        <desk-analysis-word-cloud :text="analysedText" />
        <iiif-viewer
            v-if="getAnalysisManifest"
            class="analysis-iiif-viewer"
            analysis
            :manifest="getAnalysisManifest"
            @newText="addText"
        />
    </div>
</template>

<script>
import IiifViewer from '@/components/global/iiifViewer/IiifViewer'
import DeskAnalysisWordCloud from '@/components/stations/3.004_desk/analysis/DeskAnalysisWordCloud'

export default {
    name: 'DeskAnalysis',
    components: { DeskAnalysisWordCloud, IiifViewer },
    data() {
        return {
            analysedText: null,
            newAnnotation: false,
        }
    },
    computed: {
        getAnalysisManifest() {
            return this.$store.getters['desk/getItem'].analysis
                ? this.$store.getters['desk/getItem'].analysis
                : null
        },
    },
    methods: {
        addText(text) {
            this.analysedText = text
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/global';

.analysis-iiif-viewer {
    //background-color: #0c5460;
    position: absolute;
    top: calc(98vw * var(--pxToVw));
    width: calc(902vw * var(--pxToVw)) !important;
    height: calc(957vw * var(--pxToVw)) !important;
    right: calc(50vw * var(--pxToVw));
}
</style>
